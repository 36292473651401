<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>

      <div class="dashhead-toolbar">
        <div class=" dashhead-toolbar-item">
          <Category
            v-model="selectedCategory"
            :procedureType="procedureType"
            :templateType="templateType">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <sgv-table
      :rKey="rKey"
      :items="procedureQualities"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <router-link
              class="text-decoration-none"
              :to="toDetail(item.id)">
              <span :class="{'text-danger': !item.isActive}">
                {{item.code}}
              </span>
            </router-link>
          </td>
          <td v-if="hidden.name">{{ item.name }}</td>
        </tr>
      </template>

      <router-link
        slot="action"
        v-if="$auth.hasRole(`procedure:${procedureType}:quality:add`)"
        :to="toDetail(0)">
        <button
          type="button"
          class="btn btn-link text-success">
          เพิ่ม
        </button>
      </router-link>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import Category from '@/views/procedure_core/Category.vue'
import { LIST_PROCEDURE_QUALITY } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `${this.templateType}${this.$form.capitalize(this.procedureType)}Detail`,
      rKey: `${this.templateType}${this.$form.capitalize(this.procedureType)}List`,
      rFields: ['filter', 'options', 'selectedCategory'],
      headers: [
        {text: 'รหัส', value: 'code', sort: true, filter: true},
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      procedureQualities: [],
      selectedCategory: 0,
    }
  },
  apollo: {
    procedureQualities: {
      query () {
        return LIST_PROCEDURE_QUALITY(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          procedureType: this.procedureType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only',
    }
  },
  methods: {
    getFilter (v) {
      const filter = {
        ...v,
        params: v.params || {},
      }
      if (this.selectedCategory === -1) {
        filter.params.isActive = false
        delete filter.params.categoryId
      } else {
        filter.params.isActive = true
        filter.params.categoryId = this.selectedCategory
      }
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {procedureQualityId: id}
      }
    }
  },
  watch: {
    selectedCategory: 'setRetaining'
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    Category
  }
}
</script>

<style lang="css" scoped>
</style>
