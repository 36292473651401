<template>
  <div class="input-group">
    <select
      class="custom-select custom-height"
      v-model="selected">
      <option v-for="item in items" :key="item.value" :value="item.value">
        {{item.text}}
      </option>
    </select>
    <div class="input-group-append">
      <label class="input-group-text pointer" @click="toDetail">
        <fa icon="pencil-alt" class="text-warning" v-if="value > 0"></fa>
        <fa icon="plus" class="text-success" v-else></fa>
      </label>
    </div>
  </div>
</template>

<script>
import { LIST_CATEGORY } from './graph/category'

export default {
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    options: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      items: [],
      selected: null
    }
  },
  apollo: {
    categories: {
      query () {
        return LIST_CATEGORY(this.templateType)
      },
      variables () {
        return {
          procedureType: this.procedureType,
          q: {
            order: 'code'
          }
        }
      },
      fetchPolicy: 'network-only',
      result (res) {
        const arr = [{text: 'ทั้งหมด', value: 0}]
        res.data.categories.forEach(v => {
          arr.push({text: v.name, value: v.id})
        })
        arr.push({text: 'ยกเลิก', value: -1})

        this.items = [...arr, ...this.options].filter(v => {
          if (!v.role) return true
          return this.$auth.hasRole(v.role)
        })

        if (arr.findIndex(v => v.value === this.value) === -1) {
          this.$emit('input', 0)
        }
      }
    },
  },
  methods: {
    toDetail () {
      this.$router.push({
        name: `${this.templateType}${this.$form.capitalize(this.procedureType)}CategoryDetail`,
        params: {categoryId: this.value > 0 ? this.value : 0},
        query: {redirect: this.$route.name}
      })
    }
  },
  watch: {
    value: {
      handler (value) {
        this.selected = value
      },
      immediate: true
    },
    selected (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="css" scoped>
.custom-height {
  max-height: 35.5px;
}
</style>
